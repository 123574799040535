import React from "react";
import SEO from "../../components/SEO";
import Layout from "../../components/Layout";
import ContactForm from "../../components/ContactForm";
import useSiteMetadata from "../../components/SiteMetadata";

function ContactPage() {
  const { companyname } = useSiteMetadata();

  return (
    <Layout className="bg-gray-50">
      <SEO
        title={`Contact Us | ${companyname}`}
        description={`Contact ${companyname} today to learn more about our exterior siding replacement services in Bay Area!`}
      />

      <div className="container mx-auto">
        <div className="max-w-2xl container p-8 mx-auto">
          <div className="mb-6">
            <h1 className="font-extrabold text-2xl md:text-3xl">
              Talk to an Expert
            </h1>
            <p className="text-base md:text-lg text-gray-600">
            Contact us today to learn more about our exterior siding replacement services in Bay Area and relax, knowing that the work is in the hands of capable and experienced professionals.



            </p>
          </div>

          <ContactForm fontsize={"text-lg"} />
        </div>
      </div>
    </Layout>
  );
}

export default ContactPage;
